import Container from "components/Common/Container";
import PublicFooter from "components/Layouts/Public/PublicFooter/PublicFooter";
import PublicLayout from "components/Layouts/Public/PublicLayout";
import PublicNav from "components/Layouts/Public/PublicNav/PublicNav";
import FAQHome from "components/PublicPages/Homepage/FAQHome";
import HomeBuiltForYou from "components/PublicPages/Homepage/HomeBuiltForYou";
import HomeDiscovery from "components/PublicPages/Homepage/HomeDiscovery";
import HomeFeaturesSection from "components/PublicPages/Homepage/HomeFeaturesSection";
import HomeHeroSection from "components/PublicPages/Homepage/HomeHeroSection";
import HomeSEO from "components/PublicPages/Homepage/HomeSEO";
import HomepageServices from "components/PublicPages/Homepage/HomepageServices";
import ProfilePreview from "components/PublicPages/Homepage/ProfilePreview";
import Testimonials from "components/PublicPages/Homepage/Testimonials";
import dynamic from "next/dynamic";
import { Analytics } from "utils/Analytics/AnalyticsHelper";

const HomeAISearch = dynamic(
  () => import("components/PublicPages/Homepage/HomeAISearch"),
  { ssr: false }
);

export const requestInviteEvent = (
  pos: string,
  type: string = "Landing Page"
): void => {
  Analytics.track("invite_clicked", {
    Position: pos,
    Type: type || "Landing Page",
  });
};

interface IHomePageProps {
  variant?: "IN" | "US" | "EU" | any;
}

const HomePage: React.FC<IHomePageProps> = () => {
  return (
    <PublicLayout>
      <Container className="public-homepage" type="fluid">
        {/* SEO Component */}
        <HomeSEO />

        {/* Common Nav */}
        <PublicNav page="home" customTheme="#F8F7F2" />

        {/* Section 1 - Hero Section */}
        <HomeHeroSection />

        {/* Section 2 - Christmas Discovery Section */}
        {/* <ChristmasDiscovery /> */}

        {/* Section 2 - Expert Discovery Section */}
        <HomeDiscovery />

        {/* Section 3 - AI Search */}
        <HomeAISearch page="Landing" />

        {/* Section 4 - Features Section */}
        <HomeFeaturesSection />

        {/* Section 6 - Services Section */}
        <HomepageServices />

        {/* Section 5 - Success Stories Section */}
        <Testimonials />

        {/* Section 3 - Linkedin preview Section */}
        <ProfilePreview />

        {/* New Section */}
        <HomeBuiltForYou />

        {/* Section 9 - B2b */}
        {/* <HomeB2B /> */}

        {/* Section 10 - FAQ */}
        <FAQHome />

        {/* Footer */}
        <PublicFooter />
      </Container>
    </PublicLayout>
  );
};

export default HomePage;
