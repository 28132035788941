import { Col, Row, Typography } from "antd";
import Container from "components/Common/Container";
import TImage from "components/Common/TImage";
import parse from "html-react-parser";
import React, { useState } from "react";
import { GoQuote } from "react-icons/go";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Analytics } from "utils/Analytics/AnalyticsHelper";

const { Title, Paragraph } = Typography;

const testimonialData: any = [
  {
    name: "Aishwarya Srinivasan",
    subtitle: "LinkedIn Top Voice",
    message:
      "Love the integrations with Calendar, Zoom and WhatsApp. Makes my life easier!",
    link: "aishwarya_srinivasan",
    expertPic: "/images/homepage/testimonials/aishwariya.JPG",
  },
  {
    name: "Joerg Storm",
    subtitle: "300K on LinkedIn",
    message: "The entire experience is just so seamless. My followers love it",
    link: "dr_joerg_storm",
    expertPic: "/images/homepage/testimonials/dr_joerg_storm.webp",
  },
  {
    name: "Xinran Waibel",
    subtitle: "Founder of Data Engineer Things",
    message:
      "Topmate is my go-to platform for scheduling 1:1 sessions and hosting webinars!",
    link: "xinran_waibel",
    expertPic: "/images/homepage/testimonials/xinran_waibel.jpg",
  },
  {
    name: "Payal & Gaurav",
    subtitle: "110K+ on Instagram",
    message: "All my monetisation now happens in one place",
    link: "payalineurope",
    expertPic: "/images/homepage/testimonials/payal-min.jpeg",
  },
  {
    name: "Lorraine Lee",
    subtitle: "Speaker, 320K on LinkedIn",
    message:
      "The team is extremely helpful and cares a lot about feedback. They keep rolling out new features too!",
    link: "lorraineklee",
    expertPic: "/images/homepage/testimonials/lorraine.webp",
  },
  {
    name: "Jessica",
    subtitle: "Global Data Lead in Energy Industry",
    message:
      "I love Topmate! It has made it seamless to schedule mentoring sessions! Big fan of Topmate’s WhatsApp integration.",
    link: "https://rebeldatascience.topmate.io/jessica_iriarte",
    expertPic: "/images/homepage/testimonials/jessica_iriarte.png",
  },
];

const processedTestimonials = testimonialData.reduce(function (
  rows,
  key,
  index
) {
  return (
    (index % 2 == 0 ? rows.push([key]) : rows[rows?.length - 1].push(key)) &&
    rows
  );
}, []);

const Testimonials: React.FC = () => {
  const [, setTestimonialsSwiper] = useState<any>(null);

  const TestimonialCard = ({ message, expertPic, name, subtitle }) => {
    return (
      <Paragraph className="testimonial-card">
        <Paragraph className="ss-description">
          <GoQuote color="#e5e5e5" size={30} style={{ marginBottom: 16 }} />
          <Paragraph className="ss-quote">{parse(message)}</Paragraph>
        </Paragraph>
        <Paragraph className="ss-footer">
          <TImage
            src={expertPic}
            className="ss-footer-dp"
            alt={name}
            width={48}
            height={48}
          />
          <Paragraph className="ss-footer-name">
            <Paragraph className="ss-topmate-user">{name}</Paragraph>
            <Paragraph>{subtitle}</Paragraph>
          </Paragraph>
        </Paragraph>
      </Paragraph>
    );
  };

  return (
    <Container type="fluid" className="homepage-success-stories">
      <Container>
        <Row justify="center">
          {/* Desktop */}
          <Col
            xs={{ span: 0, offset: 0 }}
            md={{ span: 22, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
          >
            <Title className="success-stories-header" level={2}>
              People love using topmate
            </Title>
            <Row className="ss-slider" gutter={{ xs: 0, md: 30 }}>
              {testimonialData.map(
                ({ name, message, link, expertPic, subtitle }) => {
                  return (
                    <Col
                      xs={24}
                      md={8}
                      key={link}
                      onClick={(): void =>
                        Analytics.track("creator_card_clicked", {
                          Expert: name,
                          Username: link,
                        })
                      }
                    >
                      <Paragraph className="swiper-slide">
                        <TestimonialCard
                          message={message}
                          expertPic={expertPic}
                          name={name}
                          subtitle={subtitle}
                        />
                      </Paragraph>
                    </Col>
                  );
                }
              )}
            </Row>
          </Col>

          {/* Mobile */}
          <Col xs={{ span: 22, offset: 0 }} md={{ span: 0 }}>
            <Title className="success-stories-header" level={2}>
              See why <span>creators</span> love Topmate 😇
            </Title>
            <Swiper
              className="ss-slider"
              modules={[Autoplay, Pagination]}
              slidesPerView={1}
              spaceBetween={20}
              autoplay={{
                delay: 5000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
              }}
              centeredSlides
              pagination={{
                clickable: true,
              }}
              loop={true}
              onAfterInit={(ev): void => {
                setTestimonialsSwiper(ev);
              }}
            >
              {processedTestimonials.map((testimonial, idx) => {
                return (
                  <SwiperSlide key={idx}>
                    {testimonial?.map(
                      ({ name, link, expertPic, message, subtitle }) => (
                        <Col
                          xs={24}
                          md={0}
                          key={name}
                          onClick={(): void =>
                            Analytics.track("creator_card_clicked", {
                              Expert: name,
                              Username: link,
                            })
                          }
                          style={{
                            display: "inline-flex",
                            flexDirection: "column",
                            marginBottom: 16,
                          }}
                        >
                          <TestimonialCard
                            message={message}
                            expertPic={expertPic}
                            name={name}
                            subtitle={subtitle}
                          />
                        </Col>
                      )
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Testimonials;
