import { Col, Row, Typography } from "antd";
import Container from "components/Common/Container";
import LottieComponent from "components/Common/LottieRender";
import TImage from "components/Common/TImage";
import DiscountCodeLottie from "public/images/lotties/discount-code.json";
import PayAsYouWishLottie from "public/images/lotties/pay-as-you-wish.json";

const { Title } = Typography;

const HomepageServices = ({ variant = "" }) => {
  return (
    <Container type="fluid" className="homepage-services">
      <Container>
        <Row justify="center">
          <Col xs={{ span: 22, offset: 0 }} md={{ span: 24, offset: 0 }}>
            <Title level={2} className="hs-title">
              Creators <b>earn more</b> on topmate
            </Title>
          </Col>

          {/* Desktop Services */}
          <Col xs={{ span: 0, offset: 0 }} md={{ span: 24, offset: 0 }}>
            <Row gutter={20}>
              <Col span={16} style={{ display: "grid", gap: 20 }}>
                <Row gutter={20}>
                  <Col span={9}>
                    <TImage
                      src="/images/homepage/service-desktop-1.svg"
                      alt="Collect Payments Globally"
                      className="img-services"
                      width={100}
                    />
                    <LottieComponent
                      src={DiscountCodeLottie}
                      play
                      style={{ left: 30, top: 30, width: 116 }}
                      loop={true}
                      className="lottie-animation"
                    />
                  </Col>
                  <Col span={15}>
                    <TImage
                      src="/images/homepage/service-desktop-2.svg"
                      alt="Highlight Unlimited Testimonials"
                      className="img-services"
                      width={100}
                    />
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={15}>
                    <TImage
                      src="/images/homepage/service-desktop-3.svg"
                      alt="Showcase your social handles"
                      className="img-services"
                      width={100}
                    />
                    <LottieComponent
                      src={PayAsYouWishLottie}
                      play
                      style={{ left: 0, top: 0, width: 174 }}
                      loop={true}
                      className="lottie-animation"
                    />
                  </Col>
                  <Col span={9}>
                    <TImage
                      src="/images/homepage/service-desktop-4.svg"
                      alt="Full stack scheduling"
                      className="img-services"
                      width={100}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <TImage
                  src={
                    variant === "gumroad"
                      ? "/images/homepage/service-desktop-variant-5.svg"
                      : "/images/homepage/service-desktop-5.svg"
                  }
                  alt="Host Unlimited Services"
                  className="img-services"
                  width={100}
                />
              </Col>
            </Row>
          </Col>

          {/* Mobile Services */}
          <Col
            xs={{ span: 22, offset: 0 }}
            md={{ span: 0, offset: 0 }}
            className="homepage-mobile-services"
          >
            <Row gutter={10}>
              <Col span={12}>
                <div style={{ position: "relative" }}>
                  <TImage
                    src="/images/homepage/service-mobile-1.svg"
                    alt="Collect Payments Globally"
                    className="img-services"
                    width={100}
                  />
                  <LottieComponent
                    src={DiscountCodeLottie}
                    play
                    loop={true}
                    className="lottie-animation discount"
                  />
                </div>
              </Col>
              <Col span={12}>
                <div style={{ position: "relative" }}>
                  <TImage
                    src="/images/homepage/service-mobile-5.svg"
                    alt="Collect Payments Globally"
                    className="img-services"
                    width={100}
                  />
                  <LottieComponent
                    src={PayAsYouWishLottie}
                    play
                    style={{ left: 0, top: 0 }}
                    loop={true}
                    className="lottie-animation ballon"
                  />
                </div>
              </Col>
            </Row>
            <TImage
              src="/images/homepage/service-mobile-2.svg"
              alt="Highlight Unlimited Testimonials"
              className="img-services"
              width={100}
            />
            <TImage
              src="/images/homepage/service-mobile-3.svg"
              alt="Host Unlimited Services"
              className="img-services"
              width={100}
            />
            {/* {variant !== "gumroad" && (
              <div style={{ position: "relative" }}>
                <LottieComponent
                  src={PayAsYouWishLottie}
                  play
                  style={{ left: 0, top: 0 }}
                  loop={true}
                  className="lottie-animation ballon"
                />
              </div>
            )} */}
            <TImage
              src="/images/homepage/service-mobile-4.svg"
              alt="Full stack scheduling"
              className="img-services"
              width={100}
            />
            {/* <TImage
              src={
                variant === "gumroad"
                  ? "/images/homepage/service-mobile-variant-5.svg"
                  : "/images/homepage/service-mobile-5.svg"
              }
              alt="Showcase your social handles"
              className="img-services"
              width={100}
            /> */}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default HomepageServices;
